var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-inline-block w-50" },
        [
          _c("b-datepicker", {
            attrs: {
              disabled: _vm.disabled,
              min: _vm.minDate,
              state: _vm.timeValidationState,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-inline-block w-50" },
        [
          _c("b-timepicker", {
            attrs: { disabled: _vm.disabled, state: _vm.timeValidationState },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-invalid-feedback",
        { attrs: { state: _vm.timeValidationState } },
        [
          _vm._t("default", function () {
            return [
              _vm.after
                ? _c("span", [
                    _vm._v(
                      " The provided time must be after " +
                        _vm._s(_vm.minTime) +
                        " "
                    ),
                  ])
                : _vm.future
                ? _c("span", [
                    _vm._v(" The provided time must be in the future "),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }