var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("h2", [_vm._v("System Settings")]),
          ]),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { title: "Maintenance Mode" } },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "end" } },
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("All dates and times are local time"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { switch: "" },
                      model: {
                        value: _vm.startEnabled,
                        callback: function ($$v) {
                          _vm.startEnabled = $$v
                        },
                        expression: "startEnabled",
                      },
                    },
                    [_vm._v(" Schedule an Offline Period ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c("date-time-picker", {
                    attrs: { disabled: !_vm.startEnabled, future: "" },
                    model: {
                      value: _vm.startTime,
                      callback: function ($$v) {
                        _vm.startTime = $$v
                      },
                      expression: "startTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { disabled: !_vm.startEnabled, switch: "" },
                      model: {
                        value: _vm.endEnabled,
                        callback: function ($$v) {
                          _vm.endEnabled = $$v
                        },
                        expression: "endEnabled",
                      },
                    },
                    [_vm._v(" Automatically end offline period ")]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { lg: "6" } },
                [
                  _c("date-time-picker", {
                    attrs: {
                      disabled: !_vm.endEnabled || !_vm.startEnabled,
                      after: _vm.startTime,
                    },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { switch: "" },
                      model: {
                        value: _vm.messageEnabled,
                        callback: function ($$v) {
                          _vm.messageEnabled = $$v
                        },
                        expression: "messageEnabled",
                      },
                    },
                    [_vm._v(" Set a system message ")]
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "auto" } }),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("p", { pre: true, attrs: { class: "text-muted" } }, [
                    _vm._v(
                      " Variables can be added into the message by enclosing them in double curly brackets "
                    ),
                    _c("code", [_vm._v("{{ }}")]),
                    _vm._v(
                      ". For example, to display the start time of the offline period, use "
                    ),
                    _c("code", [_vm._v("{{ start-time }}")]),
                    _vm._v(". "),
                  ]),
                  _c("b-collapse", { attrs: { id: "sys-msg-vars" } }, [
                    _vm._v(" Supported variables: "),
                    _c("ul", { staticClass: "list-untyled card-columns" }, [
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("start-time")
                              },
                            },
                          },
                          [_vm._v("start-time")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("start-date")
                              },
                            },
                          },
                          [_vm._v("start-date")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("start-datetime")
                              },
                            },
                          },
                          [_vm._v("start-datetime")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("end-time")
                              },
                            },
                          },
                          [_vm._v("end-time")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("end-date")
                              },
                            },
                          },
                          [_vm._v("end-date")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("end-datetime")
                              },
                            },
                          },
                          [_vm._v("end-datetime")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("duration")
                              },
                            },
                          },
                          [_vm._v("duration")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("hours")
                              },
                            },
                          },
                          [_vm._v("hours")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "code",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insertVariable("mins")
                              },
                            },
                          },
                          [_vm._v("mins")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "b-link",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.sys-msg-vars",
                          modifiers: { "sys-msg-vars": true },
                        },
                      ],
                    },
                    [_vm._v("Show supported variables")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "10" } },
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: "Set a message to display",
                      disabled: !_vm.messageEnabled,
                    },
                    model: {
                      value: _vm.statusMessage,
                      callback: function ($$v) {
                        _vm.statusMessage = $$v
                      },
                      expression: "statusMessage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.variants,
                      disabled: !_vm.messageEnabled,
                    },
                    model: {
                      value: _vm.statusVariant,
                      callback: function ($$v) {
                        _vm.statusVariant = $$v
                      },
                      expression: "statusVariant",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !!_vm.statusMessage && _vm.messageEnabled
            ? _c(
                "b-row",
                {
                  staticClass: "mt-2",
                  attrs: { cols: "12", "no-gutters": "" },
                },
                [
                  _c("b-col", [_vm._v(" Preview: ")]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("the-system-message", {
                        attrs: {
                          show: "",
                          "show-close": false,
                          message: _vm.statusMessage,
                          variant: _vm.statusVariant,
                          "offline-start": _vm.startTime,
                          "offline-end": _vm.endTime,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-3", attrs: { "align-h": "end" } },
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: { disabled: _vm.disableSaveButton, block: "" },
                      on: { click: _vm.setMaintenanceMode },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }